/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

dashboard mat-card {
  margin-top: 2px;

  @media (min-width: 992px) {
    margin-top: 10px;
    height: 400px;
    margin-bottom: 1rem;
  }
}

list mat-card {
  margin: 20px 0;
}

form mat-form-field {
  width: 100%;
}

form input {
  width: 100%;
}

.ww-100 {
  width: 100%;
}
